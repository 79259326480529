<template>
  <span
    v-if="!isWorkspaceSettingsPage"
    class="header-icon"
    @click="TOGGLE_CHOOSE_SERVICE_MODAL"
  >
    <IconLayers />
  </span>
</template>

<script>
import { mapMutations } from 'vuex'
import IconLayers from '@/assets/icons/layers.svg'

export default {
  name: 'ChangeServiceIcon',
  components: {
    IconLayers,
  },

  computed: {
    isWorkspaceSettingsPage () {
      return this.$route.name.includes('ws-settings-workspace') ||
        this.$route.name.includes('settings-profile') ||
        this.$route.name.includes('settings-security') ||
        this.$route.name.includes('settings-display')
    },
  },

  methods: {
    ...mapMutations('services', [
      'TOGGLE_CHOOSE_SERVICE_MODAL',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.header-icon {
  $headerSquareSide: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  width: $headerSquareSide;
  min-width: $headerSquareSide;
  height: $headerSquareSide;
  background-color: $lightIconBackground;
  border-radius: 4px;
  cursor: pointer;

  @include dark-theme {
    background-color: $darkThemeBgOne;

    svg {
      path {
        &:first-child {
          fill: $lightGrey;
        }

        &:last-child {
          fill: $grey;
        }
      }
    }
  }
}
</style>
